<template>
    <div >
        <div style="width: 750px; margin: auto; font-family: Times New Roman; font-size: 11px" id="salarySlip">
            <table class="salarySlip" width="100%" border="0" cellspacing="0" cellpadding="2"  >
                <tr>
                    <th colspan="2">
                        <img style="height: 52px;
                            width: 80%;
                            padding: 5px 10px 5px 10px;" width="100%" :src="require(`Assets/images/netedge-logo.png`)" alt="logo" />
                    </th>
                    <th colspan="3" align="center" style="border-right: none">
                        <strong>

               <p>{{data.Company}}</p> 
                </strong>
                 {{data.RegisteredAddress}}
                    </th>
                </tr>
                <tr>
                    <th>
                        &nbsp;
                    </th>
                    <th colspan="4" align="center" style="border-right: none">
                        <strong>
                            <Label ID="Label1"  Class="AlLeft" Text="SALARY SLIP FOR THE M/O"></Label>
                            <Label ID="lblDate" Class="AlRight"  Text=""></Label></strong>

                    </th>
                </tr>
                <tr>
                    <th align="left">
                        <strong class="tdPadding">Employee No.</strong>
                    </th>
                    <th colspan="2" align="center" class="tdPadding">
                        <strong>
                            <Label>{{ data.empcode }}</Label></strong>
                    </th>
                    <th align="left" colspan="">
                        <strong class="tdPadding" >Days Paid </strong>
                    </th>
                    <th colspan="2" align="center" class="tdPadding">
                        <strong>
                            <Label>{{ data.DaysPaid }}</Label></strong>
                    </th>
                </tr>
                <tr>
                    <th align="left">
                        <strong class="tdPadding">Employee Name.</strong>
                    </th>
                    <th colspan="2" align="center">
                        <strong>
                            <Label>{{data.name}}</Label></strong>
                    </th>
                    <th align="left" colspan="">
                        <strong class="tdPadding">PF No.</strong>
                    </th>
                    <th colspan="" align="center" style="border-right: none">
                        <strong>
                            <Label >{{ data.PF }}</Label></strong>
                    </th>
                </tr>
                <tr>
                    <th align="left">
                        <strong class="tdPadding">Designation</strong>
                    </th>
                    <th colspan="2" align="center">
                        <strong>
                            <Label>{{ data.Designation }}</Label></strong>
                    </th>
                    <th align="left" colspan="">
                        <strong class="tdPadding">Standard Chartered Bank</strong>
                    </th>
                    <th colspan="" align="center" style="border-right: none">
                        <strong>
                            <Label>{{ data.BankAccount }}</Label></strong>
                    </th>
                </tr>
                <tr>
                    <th width="31%" align="center">
                        <strong >EARNINGS</strong>
                    </th>
                    <th width="15%" align="center">
                        <strong>RATE (Rs.)</strong>
                    </th>
                    <th width="15%" align="center">
                        <strong>AMOUNT (Rs.)</strong>
                    </th>
                    <th width="24%" align="center">
                        <strong>DEDUCTIONS</strong>
                    </th>
                    <th width="15%" align="center" style="border-right: none">
                        <strong>AMOUNT (Rs.)</strong>
                    </th>
                </tr>
                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                        BASIC
                    </td>
                    <td align="right" class="tdPadding">
                        <Label>{{data.BasicPre?data.BasicPre.toLocaleString('en-IN'):''  }}</Label>
                    </td>
                    <td align="right">
                        <Label>{{ data.Basic.toLocaleString('en-IN')?data.Basic:'' }}</Label>
                    </td>
                    <td class="tdPadding">
                       PF
                    </td>
                    <td align="right">
                        <Label ID="lblLoan"  Text="">{{ data.PF?data.PF.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                        HRA
                    </td>
                    <td align="right">
                        <Label class="tdPadding">{{ data.HRAPre?data.HRAPre.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td align="right">
                        <Label>{{ data.HRA?data.HRA.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td class="tdPadding">
                        TDS
                    </td>
                    <td align="right">
                        <Label class="tdPadding">{{  data.TDS?data.TDS.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                </tr>
                <tr>
                    <td >
                 
                            <strong >ALLOWANCE</strong>
                  
                    </td>
                    <td align="right">
                        <Label ID="lblAddAllowR"  Text=""></Label>
                    </td>
                    <td align="right">
                        <Label ID="lblAddAllowA"  Text=""></Label>
                    </td>
                    <td>
                        &nbsp;  
                    </td>
                    <td align="right">
                        <Label ID="lblPF"  Text=""></Label>
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                        SHIFT ALLOWANCE

                    </td>
                    <td align="right">
                        <Label>{{ data.ShiftPre?data.ShiftPre.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td align="right">
                        <Label >{{ data.shift?data.shift.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td class="tdPadding">
                        LOANS/ADVANCES
                    </td>
                    <td align="right">
                        <Label class="tdPadding">{{data.Advance?data.Advance:''}}</Label>
                    </td>
                    
                </tr>
       
                <tr>
                    <td class="tdPadding">
                        PROFESSIONAL ALLOWANCE
                    </td>
                    <td align="right">
                        <Label  >{{ data.performance?data.performance.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td align="right">
                        <Label ID="lblMedicalAllowA"  Text=""></Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                        PERFORMANCE PAY
                    </td>
                    <td align="right">
                        <Label ID="lblShiftAllowR"  Text="">{{ data.performancePay?data.performancePay.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td align="right">
                        <Label ID="lblShiftAllowA"  Text=""></Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td>
                       <strong>REIMBURSEMENT</strong>  
                    </td>
                    <td align="right">
                        <Label ID="lblConveyanceAllowR"  Text=""></Label>
                    </td>
                    <td align="right">
                        <Label ID="lblConveyanceAllowA"  Text=""></Label>
                    </td>
                    <td class="tdPadding">
                        OTHER DEDUCTIONS
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                        MOBILE PHONE REIMBURSEMENT
                    </td>
                    <td align="right">
                        <Label>{{ data.MobileBill?data.MobileBill.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td align="right">
                        <Label ID="lblFamilyAllowA"  Text=""></Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                      
                        CONVEYANCE REIMBURSEMENT
                    </td>
                    <td align="right">
                        <Label>{{ data.ConveyencePre?data.ConveyencePre.toLocaleString('en-IN'):''}}</Label>
                    </td>
                    <td align="right">
                        <Label ID="lblarreaAllowR"  Text="">{{ data.Conveyance?data.Conveyance.toLocaleString('en-IN'):''}}</Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding"> 
                     BUSINESS DEVLOPMENT REIMBURSEMENT
                    </td>
                    <td align="right">
                        <Label ID="lblIncentiveA"  Text=""></Label>
                    </td>
                    <td align="right">
                        <Label ID="lblIncentiveR"  Text=""></Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                     
                        MEDICAL REIMBURSEMENT
                    </td>
                    <td align="right">
                        <Label ID="lblIncentiveA"  Text="">{{ data.MedicalPre?data.MedicalPre.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td align="right">
                        <Label ID="lblIncentiveR"  Text="">{{ data.MedicalReimb?data.MedicalReimb.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td>
                     
                       <strong>ARREARS / INCENTIVES</strong> 
                    </td>
                    <td align="right">
                        <Label ID="lblIncentiveA"  Text=""></Label>
                    </td>
                    <td align="right">
                        <Label ID="lblIncentiveR"  Text=""></Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td class="tdPadding">
                     
                        ARREARS / INCENTIVES
                    </td>
                    <td align="right">
                        <Label>{{ data.Arrear?data.Arrear.toLocaleString('en-IN'):'' }}</Label>
                    </td>
                    <td align="right">
                        <Label ID="lblIncentiveR"  Text=""></Label>
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>TOTAL EARNINGS </strong>
                    </td>
                    <td align="right">
                        <strong>
                            <Label >{{ data.TotalPre?data.TotalPre.toLocaleString('en-IN'):'' }}</Label></strong>
                    </td>
                    <td align="right">
                        <strong>
                            <Label ID="lblTEAllowA"  Text="">{{ data.TotalEarnings?data.TotalEarnings.toLocaleString('en-IN'):'' }}</Label></strong>
                    </td>
                    <td class="tdPadding">
                        <strong>TOTAL DEDUCTIONS </strong>
                    </td>
                    <td align="right">
                        <strong>
                            <Label >{{ data.TotalDeduction?data.TotalDeduction.toLocaleString('en-IN'):'' }}</Label></strong>
                    </td>
                </tr>
                <tr>
                    <th align="left" colspan="5" style="border-top: #000000 solid 3px; border-right: none">
                        <br />
                        <strong>NET PAY
                            <Label style="margin-left: 200px;">{{ data.NetPay?data.NetPay.toLocaleString('en-IN'):'' }}</Label></strong>
                        <br />
                        <strong>
                            <Label></Label>({{data.NetPay?numberIntoWords(data.NetPay):''}})</strong>
                        <br />
                        <br />
                        <br />
                        This is computer generated payslip. No signature required
                        <br />
                    </th>
                </tr>
                <th colspan="5" align="right" style="border-right: none">
                    <strong>
                        <Label ID="lblAuthorisedSign"  Text="Authorised Signatory"></Label></strong>
                </th>
            </table>
        </div>
        <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>  
          <FormButton type="success" @click="printSlip()">Print</FormButton>  
        </ButtonGroup>
      </div>
    </FormRow>
    </div>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import DateUtil from 'Utils/dateUtil';
    import {getSalaryFixation} from '../api';
    export default {
        name: "Detail",
        mixins: [loadingMixin],
        // components: {
        //     FormWrapper,
        //     PageTitle
        // },
        props: ["data"],
        data() {
            return {

            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        methods:{
            printSlip(){
// Get HTML to print from element
const prtHtml = document.getElementById('salarySlip').innerHTML;
// Get all stylesheets HTML
let stylesHtml = '';
for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
  stylesHtml += node.outerHTML;
}
// Open the print window
const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);
WinPrint.document.close();
WinPrint.focus();
setTimeout(() => {
WinPrint.print();
},1000);
// WinPrint.close();
            },
             numberIntoWords(n) {
    if (n < 0)
      return false;
	 let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
	 let  double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
	let  below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
	if (n === 0) return 'Zero'
	function translate(n) {
		let word = ""
		if (n < 10) {
			word = single_digit[n] + ' '
		}
		else if (n < 20) {
			word = double_digit[n - 10] + ' '
		}
		else if (n < 100) {
			let rem = translate(n % 10)
			word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
		}
		else if (n < 1000) {
			word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
		}
		else if (n < 1000000) {
			word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
		}
		else if (n < 1000000000) {
			word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
		}
		else {
			word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
		}
		return word
	}
	let result = translate(n) 
	return result.trim()+'.'
}
        }
    }
</script>
<style lang="scss">
 .Netpay
                {
                    margin-left: 110px;
                }
                .AlLeft
                {
                    margin-left: 30px;
                    float: left;
                }
                .AlRight
                {
                    float: left;
                    margin-left: 110px;
                }
                .salarySlip
                {
                    border: #000000 solid 2px;
                    border-right: #000000 solid 2px;
                    border-bottom: none;
                }
                .salarySlip th
                {
                    background: #ffffff;
                    border-right: #000000 solid 2px;
                    border-bottom: #000000 solid 2px;
                    font-size: 14px;
                }
                .salarySlip tr td
                {
                    border-right: #000000 solid 1px;
                    border-bottom: #000000 solid 1px;
                    background: #ffffff;
                    font-size: 12px;
                    padding: 3px;
                }
                .salarySlip tr td table td
                {
                    border-right: #000000 solid 0px;
                    border-bottom: #000000 solid 0px;
                    background: #ffffff;
                }

.tdPadding{
    padding: 3px;
}
#salarySlip th td {
  padding: 4px;

}
</style>