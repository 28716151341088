<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Salary Slip" slot="title" link="/helpContent/salarySlip"/>
    <ValidationObserver ref="validator" >
        <FormRow>
            <div class="col-sm-2">
              <Year label="Year" v-model="filtering.year" rules="required" />
            </div>
            <div class="col-sm-2" >
              <InputMonth
                label="Month"
                :items="monthsList"
                item-name="NAME"
                item-value="id"
                v-model="filtering.month"
              />
            </div>
            <div class="col-sm-2">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="filtering.staffId"
            rules="required"
          />
        </div>
            <div class="col-sm-3">
                <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                    <Icon type="ios-search" />
                </FormButton>
            </div>
        </FormRow>
    </ValidationObserver>
    <salary :data="salaryData[0]" v-if="salaryData.length>0" />
      <p v-if="salaryData.length==0">No salary slip found</p>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import loadingMixin from "Mixins/loadingMixin";
  import { getSalaryList, getAllStaff } from "../api";
  import Year from "../../../components/Year";
  import { ValidationObserver } from 'vee-validate';
  import salary from "./salarySlip";
  export default {
    name: "List",
    mixins: [loadingMixin],
    components: {
      FormWrapper,
      PageTitle,
      Year,
      ValidationObserver,
      salary
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    data() {
      return {
        filtering: {
          year: new Date().getFullYear(),
          month: new Date().getMonth(),
          staffId: null,
        },
        staffList: [],
        monthsList: [],
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        salaryData: [],
        showMessage: {
          isVisible: false,
          message: "",
        },
      };
    },
    created() {
      this.staffListData();
    },
    methods: {
      async staffListData() {
        // this.showLoader();
       await getAllStaff().then((res) => {
          this.staffList = res.data;
            this.filtering.staffId = this.userData.user_id;
        });
        this.getDataByFilter();
        this.hideLoader()
      },
      search() {
        this.pagination.page = 1;
        this.getDataByFilter();
      },
      selectChange(data) {
        this.selectedRows = data;
      },
      sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
      getDataByFilter() {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            setTimeout(() => {
              this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;
              
              const filters = {};
              filters.year = this.filtering.year;
              filters.month = this.filtering.month;
              filters.staffId = this.filtering.staffId;
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startIndex: page,
                      pagesize: perPage,
                    },
                  ],
                },
              };

              getSalaryList(data)
                .then(this.handleResponse)
                .catch(this.handleError);
            }, 50);
          }
        });
      },
      pageChange(page) {
        this.pagination.page = page;
        this.getDataByFilter();
      },
      handleResponse(response) {
        this.hideLoader();
        this.salaryData = response.data;
        // this.pagination.total = response.NumberOfRecords;
      },
    },
  };
</script>

<style lang="scss" module>
  .popupRow {
    padding: 5px 0 15px;
  }
  .pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
  }

  .paginationText {
    margin: 0 20px;
  }

  .paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 200ms ease;
  }
  .paginationButton:hover {
    background: #57a3f3;
    color: #fff;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }
</style>
